import { NextPage } from 'next'
import React from 'react'
import PageTemplate from '@components/Templates/Page'
import ChannelsList from '@components/Pages/Index/ChannelsList'

const Index: NextPage = () => {
	return (
		<PageTemplate>
			<ChannelsList />
		</PageTemplate>
	)
}

export default Index
