import React from 'react'
import { Flex, Box } from 'rebass'
import Style, { contentClasses } from './style'
import cx from 'classnames'

interface Props {
	storeChannels?: any
	title?: string
	text?: string
	count?: number
	children?: any
	isSpinning?: boolean
	cta?: any
	link?: any
	m?: any
	w?: any
	h?: any
	p?: any
	overflow?: string
	minH?: string | number
	noXPadding?: boolean
	titleSize?: string | number
	subtitleSize?: string | number
	headerChildren?: any
	className?: string
}

const Content: React.FC<Props> = ({
	children,
	isSpinning,
	title,
	text,
	count = null,
	cta,
	link,
	m,
	w = 1,
	h = 'auto',
	p = '30px 40px',
	overflow = 'auto',
	minH,
	noXPadding = false,
	titleSize = '24px',
	subtitleSize = '16px',
	headerChildren,
	className
}) => {
	return (
		<Flex
			width={w}
			height={h}
			css={[Style.el, isSpinning ? Style.spinning : false]}
			alignItems="flex-start"
			m={m}
			p={noXPadding ? 0 : p}
			py={noXPadding ? 30 : p}
			minHeight={minH}
			overflow={overflow}
			className={cx(contentClasses.root, className)}
		>
			<Flex width={1} flexWrap="wrap" justifyContent="space-between" alignContent="flex-start" height={'100%'}>
				<Flex
					width={1}
					justifyContent="space-between"
					px={noXPadding ? 40 : 0}
					className={contentClasses.header}
				>
					<Flex flexDirection={'column'}>
						{title && (
							<Flex css={Style.title} mb={text ? 10 : 30} width={1} fontSize={titleSize}>
								{title}{' '}
								{count !== null && (
									<Box as="p" css={Style.count}>
										({count})
									</Box>
								)}
							</Flex>
						)}
						{text && (
							<Flex css={Style.text} fontSize={subtitleSize} mb={30} width={1}>
								{text}
							</Flex>
						)}
					</Flex>
					<Flex>{headerChildren}</Flex>
					{cta && <Box mb={30}>{cta}</Box>}
					{link && (
						<Box css={Style.link} as="a" target={link.target} href={link.href}>
							{link.label}
						</Box>
					)}
				</Flex>
				{!isSpinning && <>{children}</>}
			</Flex>
		</Flex>
	)
}

export default Content
