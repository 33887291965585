import { css } from '@emotion/react'
import Theme from '@styles/theme'
import Spinner from '@assets/img/spinner.gif'

const PREFIX = 'content'

export const contentClasses = {
	root: PREFIX,
	header: `${PREFIX}-header`
}

export default {
	el: css`
		background-color: ${Theme.colors.blue2};
		border-radius: 30px;
		font-family: 'PlusJakartaSans';
	`,

	spinning: css`
		background: ${Theme.colors.blue2} url(${Spinner}) no-repeat center;
		background-size: 30px 30px;
	`,

	title: css`
		color: ${Theme.colors.white()};
		text-align: left;
		font-weight: bold;
	`,

	count: css`
		color: ${Theme.colors.grey3};
		margin-left: 5px;
	`,
	text: css`
		color: ${Theme.colors.grey3};
		font-weight: normal;
	`,

	link: css`
		color: ${Theme.colors.grey3};
		text-decoration: underline;
		font-size: 12px;
		&:hover {
			opacity: 0.8;
		}
		height: 20px;
	`
}
