import { css } from '@emotion/react'
import Theme from '@styles/theme'

export default {
	el: css`
		font-family: 'PlusJakartaSans';
	`,

	item: css`
		border: 1px solid ${Theme.colors.grey2};
		cursor: pointer;
		border-radius: 20px;
		position: relative;
		transition: opacity 0.6s ${Theme.eases.outExpo} 0s;
		&:hover {
			opacity: 0.7;
		}
	`,

	image: css`
		overflow: hidden;
		position: relative;
		transform: translate3d(0, 0, 0);
		border-radius: 6px;
	`,

	name: css`
		font-weight: bold;
		color: ${Theme.colors.white(1)};
	`,

	users: css`
		font-weight: normal;
		color: ${Theme.colors.grey3};
	`,

	searchWrap: css`
		width: 100%;
		padding: 0 0 45px 0;
		position: relative;
	`,

	searchInput: css`
		border: none;
		outline: none;
		background: none;
		font-size: 40px;
		font-weight: normal;
		border-bottom: 1px solid ${Theme.colors.grey3};
		width: 100%;
		padding: 15px 0;
		color: ${Theme.colors.white()};
	`,

	demosToggle: css`
		position: absolute;
		top: 30px;
		right: 0;
		padding: 10px 10px 10px 0;
		cursor: pointer;
	`

	// role: css`
	// 	background-color: ${Theme.colors.white(0.1)};
	// 	color: ${Theme.colors.white()};
	// 	padding: 3px 7px;
	// 	border-radius: 20px;
	// 	text-transform: uppercase;
	// 	font-weight: bold;
	// 	display: inline-block;
	// 	position: absolute;
	// 	right: 20px;
	// `
}
