import Content from '@components/Micro/Content'
import DeleteButton from '@components/Micro/DeleteButton'
import Placeholder from '@components/Micro/Image'
import Spinner from '@components/Micro/Spinner'
import EmptyState from '@components/Templates/EmptyState'
import { channelRoutes } from '@helpers/ChannelRoutes'
import { useAuth } from '@helpers/contexts/authUserContext'
import { useChannelsContext } from '@helpers/contexts/channelsContext'
import { useGlobalContext } from '@helpers/contexts/global'
import { ConfirmPopinTypes } from '@helpers/contexts/global/logics'
import { useAxiosCall } from '@helpers/hooks/useAxios'
import Theme from '@styles/theme'
import Link from 'next/link'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Box, Flex } from 'rebass'
import { EditChannelPopinButton } from '../ChannelEditPopin'
import Style from './style'
import { routes } from '@helpers/hooks/useAplozeApi'

const Channels: React.FC = () => {
	const [isLoading, setIsLoading]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(true)
	const [searchQuery, setSearchQuery] = useState('')
	const [filteredChannels, setFilteredChannels] = useState([])
	const [channelToDelete, setChannelToDelete] = useState('')
	const { channels, isSuperAdmin, isAdmin, setChannels, fetchChannels } = useChannelsContext()
	const { openConfirmPopin, closeConfirmPopin, openErrorNotification, openSuccessNotification } = useGlobalContext()
	const { authUser } = useAuth()
	const [{ data, loading }, deleteChannel] = useAxiosCall({ method: 'DELETE' })

	const onDeleteHandler = async (channelId: string, event) => {
		event.stopPropagation()
		openConfirmPopin(
			ConfirmPopinTypes.DELETE_CHANNEL,
			() => {
				deleteChannel({ url: routes.channel(channelId) })
				setChannelToDelete(channelId)
				closeConfirmPopin()
			},
			() => {
				closeConfirmPopin()
			}
		)
	}

	useEffect(() => {
		if (channels?.length) {
			setIsLoading(false)
			setFilteredChannels(channels)
		}
	}, [channels])

	useEffect(() => {
		if (searchQuery.length > 0) {
			let selecteds = []
			channels.map((channel) => {
				if (channel.name.toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) !== -1) {
					selecteds.push(channel)
				}
			})
			setFilteredChannels(selecteds)
		} else {
			setFilteredChannels(channels)
		}
	}, [searchQuery])

	useEffect(() => {
		if (authUser) {
			fetchChannels()
		}
	}, [authUser])

	useEffect(() => {
		if (!loading && data === '') {
			setChannels(channels.filter((channel) => channel.uuid !== channelToDelete))
			openSuccessNotification('Channel deleted successfully')
			setChannelToDelete('')
		} else {
			if (channelToDelete && !loading) {
				openErrorNotification('An error occured while deleting the channel with id ' + channelToDelete)
				setChannelToDelete('')
			}
		}
	}, [data, loading])

	const inputChangeHandler = (e) => {
		setSearchQuery(e.target.value)
	}

	const getLinkByRole = (channel) => {
		const route = channelRoutes.find((route) => route.admin === isAdmin).url
		return channel.uuid + route
	}

	return (
		<Flex width={1} minHeight={'calc(100vh - 91px)'}>
			<Content
				m={'50px 60px'}
				title={'All channels'}
				text={channels?.length ? 'Select the channel you want to enter' : null}
				cta={isSuperAdmin ? <EditChannelPopinButton onClose={fetchChannels} /> : null}
				count={filteredChannels?.length}
				isSpinning={isLoading}
				minH={'455px'}
				overflow={!filteredChannels?.length ? 'hidden' : 'auto'}
			>
				{isSuperAdmin && (
					<Box css={Style.searchWrap}>
						<Box
							as="input"
							onChange={inputChangeHandler}
							value={searchQuery}
							css={Style.searchInput}
							type={'text'}
							placeholder={'Search...'}
							className="search-input"
						></Box>
					</Box>
				)}
				{!isLoading &&
					(channels?.length ? (
						filteredChannels?.length &&
						filteredChannels.map((channel) => (
							<Link href={getLinkByRole(channel)} key={channel.uuid}>
								<Flex
									as="a"
									justifyContent="space-between"
									alignItems="center"
									width={'100%'}
									css={Style.item}
									p={20}
									mb={10}
								>
									<Flex width={1} alignItems="center">
										<Box css={Style.image} width={40} mr={14}>
											<Placeholder
												sizes={[100, 100]}
												media={
													channel.picture || {
														url: 'medias/default/avatar-100x100.jpg'
													}
												}
												color={Theme.colors.blue2}
												fit="cover"
											/>
										</Box>
										<Flex flexWrap="wrap" height={'100%'} alignItems="center">
											<Box width={1} css={Style.name} fontSize={'16px'}>
												{channel.name}
											</Box>
											{channel.users?.length && (
												<Box width={1} css={Style.users} fontSize={'14px'}>
													{channel.users.length}{' '}
													{channel.users.length === 1 ? 'user' : 'users'}
												</Box>
											)}
										</Flex>
									</Flex>
									{loading && channel.uuid === channelToDelete ? (
										<Spinner width={30} height={30} />
									) : (
										<DeleteButton onDeleteHandler={(e) => onDeleteHandler(channel.uuid, e)} />
									)}
								</Flex>
							</Link>
						))
					) : (
						<EmptyState
							description="You don't have access to any channel for the moment"
							title="No channel acccess"
							iconName="events"
						/>
					))}
			</Content>
		</Flex>
	)
}

export default Channels
