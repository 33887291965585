import React, { useRef } from 'react'
import { Flex, Box } from 'rebass'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Style from './style'
import Icon from '@components/Micro/Icon'
import Link from 'next/link'
import { useAuth } from '@helpers/contexts/authUserContext'
import AccountBlock from '@components/Templates/AccountBlock'

interface Props {
	storeUser?: any
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Header: React.FC<Props> = () => {
	const accountRef = useRef(null)
	const { authUser } = useAuth()

	return (
		<Flex css={Style.el} width={1} justifyContent="space-between" alignItems={'center'} flexWrap="wrap" height={91}>
			<Link href="/">
				<Box as="a" width={22} height={22} ml={60} css={Style.logo}>
					<Icon name={'logo'} width="100%" height="100%" fill={'#fff'} />
				</Box>
			</Link>
			{authUser && <AccountBlock innerRef={accountRef} position="header" />}
		</Flex>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
