import Icon from '../Icon'
import { DeleteBox, DeleteStep } from './style'
import Theme from '@styles/theme'
import { deleteButtonClasses } from './classes'
import cx from 'classnames'

interface DeleteButtonProps {
	onDeleteHandler: (props?: any) => void
	big?: boolean
	className?: string
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ onDeleteHandler, big = false, className }) => {
	return (
		<DeleteBox className={cx(deleteButtonClasses.root, className)} big={big}>
			<DeleteStep onClick={onDeleteHandler}>
				<Icon name={'trash'} width="100%" height="100%" fill={Theme.colors.white()} />
			</DeleteStep>
		</DeleteBox>
	)
}

export default DeleteButton
