export const channelRoutes = [
	{
		icon: 'stats',
		label: 'Dashboard',
		url: '',
		admin: true
	},
	{
		icon: 'target',
		label: 'Live',
		url: '/events',
		admin: false
	},
	{
		icon: 'play_round',
		label: 'Highlight',
		url: '/highlights',
		admin: true
	},
	{
		icon: 'products',
		label: 'Products',
		url: '/products',
		admin: true
	},
	{
		icon: 'branding',
		label: 'Branding',
		url: '/brandings',
		admin: true
	}
]
