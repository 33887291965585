import styled from '@emotion/styled'
import Theme from '@styles/theme'

export const DeleteBox = styled.div<{ big?: boolean }>`
	width: ${({ big }) => (big ? '35px' : '30px')};
	flex-shrink: 0;
`

export const DeleteStep = styled.div`
	background: ${Theme.colors.red()};
	padding: ${Theme.spacing(2)};
	border-radius: 10px;
	cursor: pointer;
	pointer-events: all;
`
