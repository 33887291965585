import React, { useState, useReducer, useEffect } from 'react'
import { Flex, Box } from 'rebass'
import Theme from '@styles/theme'
import Popin, { PopinTitle, PopinSubtitle } from '@components/Templates/Popin'
import Cta from '@components/Micro/Button'
import Input from '@components/Micro/Fields/Input'
import { FormWrapper } from './style'
import { buildSelectEvent, formReducer, validateForm } from './logics'
import { editPopinInitialFormState, ActionType, FormNames, initialErrorsState, ErrorState } from './constants'
import SelectField from '@components/Micro/Fields/Select'
import { availableCurrencies, availableLocales, channelSubscriptionTypes } from 'types/channel'
import { routes } from '@helpers/hooks/useAplozeApi'
import { useAxiosCall } from '@helpers/hooks/useAxios'
import { useGlobalContext } from '@helpers/contexts/global'
import SelectInput from '@components/Micro/Fields/SelectV2'
import Utils from '@utils/index'

interface EditChannelPopinProps {
	currentChannel?: any
	innerRef?: any
	onSubmit?: any
	perPage?: number
	isOpened: boolean
	setOpen: Function
	onClose: Function
}

interface EditChannelPopinButtonProps {
	onClose: Function
}

const channelSubscriptionTypeOptions = channelSubscriptionTypes.map((type) => ({
	value: type,
	label: Utils.capitalize(type)
}))

export const EditChannelPopinButton: React.FC<EditChannelPopinButtonProps> = ({ onClose }) => {
	const [open, setOpen] = useState<boolean>(false)

	return (
		<>
			<Cta
				justifyContent={'flex-start'}
				color={Theme.colors.grey1}
				label="Add channel"
				onClick={() => setOpen(true)}
			/>
			<EditChannelPopin isOpened={open} setOpen={setOpen} onClose={onClose} />
		</>
	)
}

const EditChannelPopin: React.FC<EditChannelPopinProps> = ({ isOpened, setOpen, onClose }) => {
	/**
	 * @States
	 */
	const [channelForm, manageChannelForm] = useReducer(formReducer, editPopinInitialFormState)
	const [errors, setErrors] = useState<ErrorState>(initialErrorsState)
	const { openSuccessNotification } = useGlobalContext()
	const [{ data: createChannelData, loading: createChannelLoading }, createChannel] = useAxiosCall({
		method: 'POST'
	})

	/**
	 * @Effects
	 */
	useEffect(() => {
		if (createChannelData) {
			manageChannelForm({ type: ActionType.reset })
			onClose()
			setOpen(false)
			openSuccessNotification('Your channel is now available and ready to use', undefined, 500)
		}
	}, [createChannelData])

	/**
	 * @Methods
	 */
	const handleClose = () => {
		setOpen(false)
		manageChannelForm({ type: ActionType.reset })
		setErrors(initialErrorsState)
	}

	const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setErrors({ ...errors, [e.target.name]: false })
		manageChannelForm({
			type: ActionType.setChannelValue,
			field: e.target.name,
			payload: e.target.value
		})
	}

	const handleSave = () => {
		if (validateForm(channelForm, setErrors)) {
			createChannel({ url: routes.channels() }, { data: channelForm })
		}
	}

	return (
		<Popin isOpened={isOpened} onClose={handleClose} hasCta={true}>
			<Flex width={0.7} flexWrap="wrap">
				<PopinTitle>Add channel</PopinTitle>
				<PopinSubtitle>Fill the information below to create new channel</PopinSubtitle>
				<FormWrapper width={1}>
					<Input
						label={'CHANNEL NAME'}
						name={FormNames.NAME}
						value={channelForm.name}
						onChange={(e) => handleFormChange(e)}
						error={errors[FormNames.NAME]}
					/>
					<Input
						label={'DOMAIN NAME'}
						name={FormNames.DOMAINNAME}
						value={channelForm.domains[0].name}
						onChange={(e) => handleFormChange(e)}
						error={errors[FormNames.DOMAINNAME]}
					/>
					<Input
						label={'DOMAIN URL'}
						name={FormNames.DOMAINHOST}
						value={channelForm.domains[0].host}
						onChange={(e) => handleFormChange(e)}
						error={errors[FormNames.DOMAINHOST]}
					/>
					<Input
						label={'FIRST USER NAME'}
						name={FormNames.USERNAME}
						value={channelForm.user.name}
						onChange={(e) => handleFormChange(e)}
						error={errors[FormNames.USERNAME]}
					/>
					<Input
						label={'FIRST USER MAIL'}
						name={FormNames.USEREMAIL}
						value={channelForm.user.email}
						onChange={(e) => handleFormChange(e)}
						error={errors[FormNames.USEREMAIL]}
					/>
					<Box width={1}>
						<SelectField
							label={'LANGUAGE'}
							name={FormNames.LANGUAGE}
							entries={[{ entries: availableLocales }]}
							position="bottom"
							initialValue={availableLocales.findIndex((item) => item.locale === 'fr')}
							onChange={(e) => handleFormChange(buildSelectEvent(e, 'locale'))}
							error={errors[FormNames.LANGUAGE]}
						/>
					</Box>
					<Box width={1}>
						<SelectField
							label={'CURRENCY'}
							name={FormNames.CURRENCY}
							entries={[{ entries: availableCurrencies }]}
							position="bottom"
							initialValue={availableCurrencies.findIndex((item) => item.code === 'EUR')}
							onChange={(e) => handleFormChange(buildSelectEvent(e, 'currency'))}
							error={errors[FormNames.CURRENCY]}
						/>
					</Box>

					<Input
						label={'BRAND LEGAL MAIL'}
						name={FormNames.BRANDLEGALEMAIL}
						value={channelForm.brandLegalEmail}
						onChange={(e) => handleFormChange(e)}
						error={errors[FormNames.BRANDLEGALEMAIL]}
					/>
					<SelectInput
						closeMenuOnSelect
						label="CHANNEL SUBSCRIPTION TYPE"
						value={channelSubscriptionTypeOptions.find(
							(option) => option.value === channelForm.subscriptionType
						)}
						options={channelSubscriptionTypeOptions}
						onSelect={(option) =>
							manageChannelForm({
								type: ActionType.setChannelValue,
								field: 'subscriptionType',
								payload: option
							})
						}
						placeholder="Select channel subscription type"
						menuPlacement="top"
					/>
				</FormWrapper>
				<Flex width={1} justifyContent="center" mt={50}>
					<Box>
						<Cta
							color={Theme.colors.grey1}
							label={'Create'}
							onClick={handleSave}
							disable={createChannelLoading}
						/>
					</Box>
				</Flex>
			</Flex>
		</Popin>
	)
}

export default EditChannelPopin
