import { CreateChannel, availableLocales, availableCurrencies } from 'types/channel'
import { Action, ActionType, FormNames, editPopinInitialFormState } from './constants'
import validate from '@helpers/validate'

export const formReducer = (state: CreateChannel, action: Action): CreateChannel => {
	switch (action.type) {
		case ActionType.setChannelValue:
			switch (action.field) {
				case FormNames.NAME:
					return { ...state, name: action.payload, user: { ...state.user, channelName: action.payload } }
				case FormNames.USERNAME:
					return { ...state, user: { ...state.user, name: action.payload } }
				case FormNames.USEREMAIL:
					return { ...state, user: { ...state.user, email: action.payload } }
				case FormNames.DOMAINHOST:
					return { ...state, domains: [{ ...state.domains[0], host: action.payload }] }
				case FormNames.DOMAINNAME:
					return { ...state, domains: [{ ...state.domains[0], name: action.payload }] }
				default:
					return { ...state, [action.field]: action.payload }
			}
		case ActionType.reset:
			return editPopinInitialFormState
		default:
			break
	}
}

export const validateForm = (form, updateErrorState) => {
	let errorState = {}

	Object.values(FormNames).forEach((fieldName) => {
		switch (fieldName) {
			case FormNames.BRANDLEGALEMAIL:
				errorState[fieldName] = !validate(form.brandLegalEmail, { type: 'email', min: 6 })
				break
			case FormNames.USEREMAIL:
				errorState[fieldName] = !validate(form.user.email, { type: 'email', min: 6 })
				break
			case FormNames.USERNAME:
				errorState[fieldName] = !validate(form.user.name, { type: 'string', min: 2, max: 100 })
				break
			case FormNames.DOMAINHOST:
				errorState[fieldName] = !validate(form.domains[0].host, { type: 'string', min: 2, max: 100 })
				break
			case FormNames.DOMAINNAME:
				errorState[fieldName] = !validate(form.domains[0].name, { type: 'string', min: 2, max: 100 })
				break
			case FormNames.CURRENCY:
				errorState[fieldName] = availableCurrencies.findIndex((cur) => form.currency === cur.code) === -1
				break
			case FormNames.LANGUAGE:
				errorState[fieldName] = availableLocales.findIndex((lang) => form.locale === lang.locale) === -1
				break
			default:
				errorState[fieldName] = !validate(form[fieldName], { type: 'string', min: 2, max: 100 })
				break
		}
	})

	updateErrorState(errorState)

	return Object.values(errorState).every((error) => error === false)
}

export const buildSelectEvent = (
	e: React.ChangeEvent<HTMLInputElement>,
	name: string
): React.ChangeEvent<HTMLInputElement> => {
	const list = name === 'locale' ? availableLocales : availableCurrencies
	const field = name === 'locale' ? 'locale' : 'code'

	return {
		...e,
		target: {
			...e.target,
			value: list[e.target.value][field],
			name
		}
	}
}
