import { CreateChannel } from '@customTypes/channel'

export type Action =
	| {
			type: ActionType.setChannelValue
			field?: string
			payload?: any
	  }
	| {
			type: ActionType.reset
	  }

export enum ActionType {
	setChannelValue = 'SET_CHANNEL_VALUE',
	reset = 'RESET'
}

export enum FormNames {
	NAME = 'name',
	DOMAINHOST = 'domainHost',
	DOMAINNAME = 'domainName',
	USERNAME = 'userName',
	USEREMAIL = 'userEmail',
	LANGUAGE = 'language',
	CURRENCY = 'currency',
	BRANDLEGALEMAIL = 'brandLegalEmail'
}

export interface ErrorState {
	[FormNames.NAME]: boolean
	[FormNames.DOMAINHOST]: boolean
	[FormNames.DOMAINNAME]: boolean
	[FormNames.USERNAME]: boolean
	[FormNames.USEREMAIL]: boolean
	[FormNames.LANGUAGE]: boolean
	[FormNames.CURRENCY]: boolean
	[FormNames.BRANDLEGALEMAIL]: boolean
}

export const initialErrorsState: ErrorState = {
	[FormNames.NAME]: false,
	[FormNames.DOMAINHOST]: false,
	[FormNames.DOMAINNAME]: false,
	[FormNames.USERNAME]: false,
	[FormNames.USEREMAIL]: false,
	[FormNames.LANGUAGE]: false,
	[FormNames.CURRENCY]: false,
	[FormNames.BRANDLEGALEMAIL]: false
}

export const editPopinInitialFormState: CreateChannel = {
	name: '',
	domains: [{ name: '', host: '' }],
	user: { name: '', email: '', role: 'admin', fromEmail: 'tech@aploze.com', channelName: '' },
	locale: 'fr',
	currency: 'EUR',
	brandLegalEmail: '',
	subscriptionType: 'starter'
}
