import { css } from '@emotion/react'
import Theme from '@styles/theme'

export default {
	el: css`
		width: 100%;
		height: 100%;
		border-bottom: 1px solid ${Theme.colors.grey2};
		position: fixed;
		top: 0;
		left: 0;
		font-family: 'PlusJakartaSans';
		background: ${Theme.colors.blue1};
		z-index: ${Theme.zIndices.header};
	`,

	logo: css`
		cursor: pointer;
	`
}
