import React from 'react'
import Header from '@components/Templates/Page/Header'
import { Box } from 'rebass'
import Style from './style'

interface Props {
	children?: any
}

const PageTemplate: React.FC<Props> = ({ children }) => {
	return (
		<>
			<Box css={Style.children}>{children}</Box>
			<Header />
		</>
	)
}

export default PageTemplate
